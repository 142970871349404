import { useMemo } from 'react';

import type {
  GalleryData,
  GIFData,
  ImageData,
  Media,
  RichContent,
  VideoData,
  DraftContent,
  Node_Type,
} from '@wix/ricos';

import {
  ensureRicosContent,
  fromPlainText,
  getPluginData,
  getText,
  getAbsoluteUrl,
} from '@wix/ricos';

export interface IItemContent {
  text?: string;
  gifs: GIFData[];
  images: ImageData[];
  videos: VideoData[];
  galleries: GalleryData[];
}

export function extractContent(
  content: RichContent | null | undefined,
): IItemContent {
  if (!content) {
    return {
      text: undefined,
      gifs: [],
      images: [],
      videos: [],
      galleries: [],
    };
  }

  return {
    text: getText(content).join(' '),
    images: prepareImages(content),
    videos: prepareVideos(content),
    galleries: prepareGalleries(content),
    gifs: getPluginData(content, 'GIF' as Node_Type),
  };
}

function prepareImages(content: RichContent): ImageData[] {
  const images: ImageData[] = getPluginData(content, 'IMAGE' as Node_Type);

  return images.map((image) => ({
    ...image,
    image: prepareMediaItem(image.image),
  }));
}

function prepareGalleries(content: RichContent): GalleryData[] {
  const galleries: GalleryData[] = getPluginData(
    content,
    'GALLERY' as Node_Type,
  );

  return galleries.map((gallery) => ({
    ...gallery,
    items: gallery.items.map((item) => ({
      ...item,
      image: !item.image
        ? undefined
        : {
            ...item.image,
            media: prepareMediaItem(item.image.media),
          },
      video: !item.video
        ? undefined
        : {
            ...item.video,
            media: prepareMediaItem(item.video.media),
            thumbnail: prepareMediaItem(item.video.thumbnail),
          },
    })),
  }));
}

function prepareVideos(content: RichContent): VideoData[] {
  const videos: VideoData[] = getPluginData(content, 'VIDEO' as Node_Type);

  return videos.map((video) => ({
    ...video,
    thumbnail: prepareMediaItem(video.thumbnail),
    video: prepareMediaItem(video.video),
  }));
}

function prepareMediaItem(media?: Media): Media | undefined {
  if (!media) {
    return undefined;
  }

  const id = media.src?.id || media.src?.url;
  const isVideo = media.duration !== undefined;

  if (!id) {
    return media;
  }

  return {
    ...media,
    src: {
      ...media.src,
      url: getAbsoluteUrl(id, isVideo ? 'video' : 'image'),
    },
  };
}

export function safeJsonParse<T extends RichContent | DraftContent>(
  input?: string | null | undefined,
): T | null | undefined {
  if (typeof input === 'undefined' || input === null) {
    return undefined;
  }

  if (typeof input !== 'string') {
    return undefined;
  }

  try {
    return JSON.parse(input);
  } catch (e) {
    return undefined;
  }
}

export function useRichContent(data: string | null | undefined) {
  return useMemo(() => {
    const content = safeJsonParse<DraftContent>(data) || data;

    if (typeof content === 'string') {
      return fromPlainText(content);
    }

    if (!content) {
      return undefined;
    }

    return ensureRicosContent(content);
  }, [data]);
}
