import React from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useInView } from 'react-intersection-observer';

import { useRichContent } from 'common/utils/ricos';
import { useController } from 'common/context/controller';
import { RicosViewer } from 'common/components/Ricos/RicosViewer';

import type { IFeedItem } from 'api/feed/types';

import { CardContent, type ICardContentProps } from 'wui/CardContent';

import { GROUPS_APP_DEFINITION_ID } from '../../../../constants';
import { getAriaId } from '../a11y';

import { ActivityContent } from './ActivityContent';

interface IProps extends Partial<ICardContentProps> {
  item: IFeedItem;
  truncate?: boolean;
}

export function FeedItemContent(props: IProps) {
  const { item, truncate, ...rest } = props;

  const { feed$ } = useController();
  const { isViewer } = useEnvironment();
  const { experiments } = useExperiments();

  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;

  const content = useRichContent(item.entity?.body?.content);

  const { ref } = useInView({
    delay: 3000,
    threshold: 0.5,
    triggerOnce: true,
    skip: !isViewer || !experiments.enabled('specs.groups.FeedItemViews'),
    onChange(inView) {
      if (inView) {
        feed$.view(groupId, feedItemId);
      }
    },
  });

  if (item.activity) {
    return (
      <CardContent ref={ref} id={getAriaId(feedItemId, 'content')} {...rest}>
        <ActivityContent item={item} />
      </CardContent>
    );
  }

  if (content) {
    return (
      <CardContent ref={ref} id={getAriaId(feedItemId, 'content')} {...rest}>
        <RicosViewer
          usage="FeedItem"
          postId={feedItemId}
          containerId={GROUPS_APP_DEFINITION_ID}
          groupId={groupId}
          truncate={truncate}
          content={content}
          contentId={item.feedItemId}
          data-hook="feed-item-ricos-viewer"
        />
      </CardContent>
    );
  }

  return null;
}

FeedItemContent.displayName = 'FeedItemContent';
